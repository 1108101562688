import React from "react"

import style from "./vdp.module.scss"
import {FacebookShareButton, EmailShareButton, FacebookIcon, EmailIcon} from "react-share";

import iconEye from "../img/icon-eye.svg"
import iconEnvelope from "../img/icon-envelope.svg"
import iconPrint from "../img/icon-print.svg"

export default function VDPHeader(props) {

  const { VIN, StockNumber, Mileage, Year, Make, Model,DealerName, Trim } = props.vehicle.VehicleInfo
  const location = typeof window !== 'undefined' && window.location
  const shareLink = location.href
  let HideNewUsed = props.HideNewUsed

  // Create email subject with the car's year, make, and model
  const subject = `Take a look at this ${Year} ${Make} ${Model}.`;

  return (
    <>
      <div className={style["header-actions-wrap"]}>
        <div className={style["h1-header__wrap"]}>
          <h1 className={style["h1-header"]}>
            <span className={style["h1-header-link"]}>
              { HideNewUsed ? Year : (props.vehicle.VehicleInfo.IsNew == true ? ("New " +  Year) : (props.vehicle.VehicleInfo.IsCertified ? "Certified Pre-owned " +  Year : "Pre-owned " +  Year))} <span className={style["h1-header__model-name"]}>
                {`${Make} ${Model} ${Trim}`}
              </span>
            </span>
          </h1>

          <ul className={style["item-specs"]}>
            {props.ShowLocationFilter &&
            <li className={style["item-spec"]}>
              <p className={style["item-spec__name"]}>Location:</p>
              <p className={style["item-spec__value"]}>{DealerName}</p>
            </li>}
            <li className={style["item-spec"]}>
              <p className={style["item-spec__name"]}>VIN #:</p>
              <p className={style["item-spec__value"]}>{VIN}</p>
            </li>
            <li className={style["item-spec"]}>
              <p className={style["item-spec__name"]}>Stock #:</p>
              <p className={style["item-spec__value"]}>{StockNumber}</p>
            </li>
            <li className={style["item-spec"]}>
              <p className={style["item-spec__name"]}>Mileage:</p>
              <p className={style["item-spec__value"]}>{Mileage}</p>
            </li>
          </ul>
        </div>

        <div className={style["header-share__wrap"]}>
          <FacebookShareButton style={{marginRight:8}} url={`${shareLink}`} >
            <FacebookIcon 
              size={32}
              round
              bgStyle={{fill: 'black'}}
              />
          </FacebookShareButton>
          <EmailShareButton subject={subject} openShareDialogOnClick onclick={() => {}} url={`${shareLink}`}>
            <EmailIcon 
              size={32}
              round
              bgStyle={{fill: 'black'}}
              />
          </EmailShareButton>

        </div>

       {/* <ul className={style["actions"]}>
           <li className={style["actions__action"]}>
            <img className={style["actions__icon"]} src={iconEye} alt="" />
            <p className={style["actions__value"]}>1,220</p>
          </li> 
       <li className={style["actions__action"]}>
            <a 
              href={`mailto:?subject=${escape(Year + ' ' + Make + ' ' + Model + '.')}`}
              className={style["actions__btn"]}>
              <img className={style["actions__icon"]} src={iconEnvelope} alt="" />
            </a>
          </li>
          <li className={style["actions__action"]}>
            <button className={style["actions__btn"]} onClick={() => window.print()}>
              <img className={style["actions__icon"]} src={iconPrint} alt="" />
            </button>
          </li>
          </ul> */}
      </div>
    </>
  )
}
